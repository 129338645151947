import React from 'react'
import { gql } from '@apollo/client'
import { BannerFragment } from './__generated__/banner'
import { IMAGE_FRAGMENT } from '../../../fragments/image'
import { HomeBanners } from '../../../components/banner/HomeBanners'
import { HomeBanner } from '../../../components/banner/HomeBanner'
import { html } from '../../../utils/html'
import { nonNull } from '../../../utils/nonNull'

export type BannerProps = {
  data: BannerFragment[]
}

export const Banner: React.VFC<BannerProps> = ({ data }) => {
  return (
    <HomeBanners>
      {nonNull(data).map((banner, i) => {
        const url = banner.bannerImage?.[0]?.url ?? undefined

        if (!url) {
          return null
        }

        return (
          <HomeBanner key={i} image src={url}>
            {banner.bannerText && (
              <HomeBanner title text={banner.bannerText}/>
            )}
            {banner.bannerSubText && (
              <HomeBanner subtitle text={html(banner.bannerSubText)}/>
            )}
            {banner.bannerButtonLink && banner.bannerButtonText && (
              <HomeBanner cta href={banner.bannerButtonLink} text={banner.bannerButtonText}/>
            )}
          </HomeBanner>
        )
      })}
    </HomeBanners>
  )
}

export const BANNER_FRAGMENT = gql`
  ${IMAGE_FRAGMENT}

  fragment BannerFragment on bannerHome_BlockType {
    bannerText
    bannerSubText
    bannerButtonText
    bannerButtonLink
    bannerImage {
      ...ImageFragment
    }
  }
`
