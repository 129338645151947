import React from 'react'
import { gql } from '@apollo/client'
import { QuickLinksFragment } from './__generated__/quickLinks'
import { QuickLinks as QuickLinksComponent } from '../../../components/QuickLinks/QuickLinks'
import { isNotNull, nonNull } from '../../../utils/nonNull'

export type QuickLinksProps = {
  data: QuickLinksFragment[]
}

export const QuickLinks: React.FC<QuickLinksProps> = ({ data }) => {
  return data ? (
    <QuickLinksComponent>
      {nonNull(data).map((quickLink, i) => quickLink.__typename !== 'homePageQuickLinks_BlockType' ? null : (
        // @ts-ignore
        <QuickLinksComponent key={i} card iconCode={quickLink.icon} title={quickLink.quickLinkTitle} href={quickLink.href || '#'}>
          {quickLink.quickLinks?.filter(isNotNull).map((subLink, i) => (
            <QuickLinksComponent key={i} item href={subLink.href || '#'}>
              {subLink.text}
            </QuickLinksComponent>
          ))}
        </QuickLinksComponent>
      ))}
    </QuickLinksComponent>
  ) : null
}

export const QUICK_LINKS_FRAGMENT = gql`
  fragment QuickLinksFragment on homePageQuickLinks_BlockType {
    icon
    href
    quickLinkTitle
    quickLinks {
      ... on quickLinks_quickLink_BlockType {
        text
        href
      }
    }
  }
`