import React, { useState } from 'react'
import { Grid } from '@material-ui/core'
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons'
import { CreditUnionIcon } from '../icon/CreditUnionIcon'
import { StyledCardContainer, StyledCardList, StyledTitleCheveron, StyledTitleText } from './styledQuickLinksCard'
import { StyledAccordion, StyledAccordionDetails, StyledAccordionSummary } from './styledAccordionCard'
import { QuickLinksItem } from './QuickLinksItem'
import { QuickLinksCardProps } from './QuickLinksCard'

export const QuickLinksAccordianCard: React.FC<QuickLinksCardProps> =  ({ title, iconCode, href, children, ...props }) => {
  const [cheveronRotate, setCheveronRotate] = useState(false)
  const handleAccordionChange = () => {
    setCheveronRotate(!cheveronRotate)
  }

  return (
    <StyledCardContainer item xs={12} sm={4} {...props}>
      <StyledAccordion onChange={handleAccordionChange}>
        <StyledAccordionSummary>
          <Grid container spacing={1} alignItems="center" justifyContent="space-between">
            <Grid item>
              <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                <Grid item>
                  <CreditUnionIcon width={33} height={33} iconCode={iconCode}/>
                </Grid>
                <Grid item>
                  <StyledTitleText>{ title }</StyledTitleText>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <StyledTitleCheveron $rotate={cheveronRotate} icon={faChevronDown}/>
            </Grid>
          </Grid>
        </StyledAccordionSummary>
        <StyledAccordionDetails>
          <StyledCardList>
            <QuickLinksItem href={href}>
              Overview
            </QuickLinksItem>
            { children }
          </StyledCardList>
        </StyledAccordionDetails>
      </StyledAccordion>
    </StyledCardContainer>
  )
}