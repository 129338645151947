import React, { useEffect, useRef } from 'react'
import Flickity from 'react-flickity-component'
import { StyledCarouselContainer, StyledFlickityComponent } from './styledCardDesignCarousel'
import { useMediaQuery, useTheme } from '@material-ui/core'
import { FlickityRefObject } from '../../types/FlickityRefObject'

export type CardDesignCarouselProps = Omit<React.ComponentProps<typeof Flickity>, 'flickityRef'> & {
  //
}

export const CardDesignCarousel: React.FC<CardDesignCarouselProps> = ({ children, ...props }) => {
  const flickityRef = useRef(null) as FlickityRefObject
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'))
  const isTablet = useMediaQuery(theme.breakpoints.down('sm'))

  useEffect(() => {
    setTimeout(() => {
      if (flickityRef.current && flickityRef.current.flkty) {
        flickityRef.current.flkty.select(1)
      }

      setTimeout(() => {
        if (flickityRef.current && flickityRef.current.flkty) {
          flickityRef.current.flkty.select(0)
        }
      }, 200)
    }, 200)
  }, [flickityRef])

  useEffect(() => {
    const groupCells = isMobile ? 1 : (isTablet ? 2 : 3)

    if (flickityRef.current && typeof flickityRef.current.flkty?.options === 'object') {
      flickityRef.current.flkty.options.groupCells = groupCells
    }
  }, [isMobile, isTablet, flickityRef])

  return (
    <StyledCarouselContainer>
      <StyledFlickityComponent ref={flickityRef} {...props} options={{
        freeScroll: true,
        wrapAround: true,
        contain: true,
        adaptiveHeight: true,
        setGallerySize: false,
        cellAlign: 'left',
      }}>
        {children}
      </StyledFlickityComponent>
    </StyledCarouselContainer>
  )
}
